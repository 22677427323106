import { Injectable } from '@angular/core';
import { DkPictureMediaQueryInterface } from './dk-picture.interface';

@Injectable({
  providedIn: 'root',
})
export class DkPictureService {
  constructor() {}

  buildMediaQueries(queries: DkPictureMediaQueryInterface[], setUnChange?: boolean) {
    queries.map(query => {
      if (!query._unChange) {
        if (query.dimensions.heigth) {
          const heigth = query.dimensions.heigth;
          query.dimensions._heigth1x = `h_${heigth}`;
          query.dimensions._heigth2x = `h_${heigth * 2}`;
          query.dimensions._heigth3x = `h_${heigth * 3}`;
        }
        if (query.dimensions.width) {
          const width = query.dimensions.width;
          query.dimensions._width1x = `w_${width}`;
          query.dimensions._width2x = `w_${width * 2}`;
          query.dimensions._width3x = `w_${width * 3}`;
        }
        if (setUnChange) {
          query._unChange = setUnChange;
        }
      }
    });
    return queries;
  }
}
