export const environment = {
  awsApiUrl: 'https://s3-eu-west-1.amazonaws.com/dkhub-dummy-data/',
  dataCache: true,
  dkApiAuth: 'Basic ZGsuZnJvdG5lbmQuZGV2OjVtMztVMjM1NGYyK1Y3Mjc0ZTg2NTJEM3U=',
  dkApiUrl: 'https://d1tnvbbiyrb33a.cloudfront.net/api/',
  feUrl: '/',
  imageServerNoImage: 'v1/dk-core-nonprod/static/no-cover',
  imageServerRoot: 'v1/dk-core-nonprod',
  imageServerUrl: 'https://res.cloudinary.com/dk-hub/image/upload',
  imageServerSeoUrl: 'https://res.cloudinary.com/dk-hub/images',
  production: false,
  searchUrl: 'https://solrcloud-nonprod.dk.com/solr/dkhub-core-dev/select?',
  solrAuth: 'Basic d2ViOjc2MnppNjRjNDBzczVlSw==',
  dkCloudinaryRoot: 'dk-core-nonprod',
  dkCloudinaryRoot__Legacy: 'DK',
  dkCoreApiUrl: 'https://yhehft7rzi.execute-api.eu-west-1.amazonaws.com/dev',
  dkCoreApiAuth: 'QECCQWOQQf5YHrSx2hCkf1YF66MnwPBe1z1yEBQ8',
};
