import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

interface LanguagesInterface {
  code: string;
  name: string;
}

export interface MarketInterface {
  code: string;
  defaultLanguage: string;
  languages: LanguagesInterface[];
  name: string;
  hidePrice: boolean;
}

export interface SiteWideLabelsInterface {
  /** The title of the `Recently Viewed` books list */
  recentlyViewed: string;
  /** The Newsletter CTA in the footer */
  footerNewsletterCTA: string;
  /** The Social media CTA in the footer */
  footerSocialCTA: string;
  /** The Newsletter CTA in the header */
  headerNewsletterCTA: string;
  /** The change country CTA */
  footerChangeCountry: string;
  /** The change country modal title */
  changeCountryTitle: string;
  /** The header's navigation dropdown */
  headerNavDropdown: string;
  /** The header's navigation categories title */
  headerNavCategories: string;
  /** The header's navigation expand categories on mobile */
  headerNavMoreCategories: string;
  /** The header's navigation retract categories on mobile */
  headerNavLessCategories: string;
  /** The header's navigation promotion title */
  headerNavPromotions: string;
  /** The Search placeholder text on desktop */
  searchPlaceholderDesktop: string;
  /** The Search placeholder text on mobile */
  searchPlaceholderMobile: string;
  /** The Search CTA */
  searchCTA: string;
  /** The book search results title */
  searchBookTitle: string;
  /** The Search view all books CTA */
  searchBookCTA: string;
  /** The Search Recently searched queries */
  searchRecent: string;
  /** The delete search CTA for desktop */
  searchDeleteDesktopCTA: string;
  /** The delete search CTA for mobile */
  searchDeleteMobileCTA: string;
  /** The text explaning the user where search queries are stored */
  searchHistoryNotice: string;
}

export interface GlobalState {
  activeMarket: MarketInterface;
  markets: MarketsResponseInterface;
  labels: SiteWideLabelsInterface;
}

export interface MarketsResponseInterface {
  [key: string]: MarketInterface;
}

export function createInitialState(): GlobalState {
  return {
    activeMarket: null,
    markets: null,
    labels: {
      changeCountryTitle: 'Select your location',
      footerChangeCountry: 'Change country',
      footerNewsletterCTA: 'Newsletter sign up',
      footerSocialCTA: 'Follow DK:',
      headerNavCategories: 'DK Categories',
      headerNavDropdown: 'All Books',
      headerNavPromotions: 'Top Promotions',
      headerNewsletterCTA: 'Newsletter',
      headerNavMoreCategories: 'View more',
      headerNavLessCategories: 'View less',
      recentlyViewed: 'Recently Viewed',
      searchBookCTA: 'View all',
      searchBookTitle: 'Books',
      searchCTA: 'Press enter to view all search results',
      searchDeleteDesktopCTA: 'Delete search history',
      searchDeleteMobileCTA: 'Clear search history',
      searchHistoryNotice: 'Your search history is only stored locally on your device. You can delete it any time.',
      searchPlaceholderDesktop: 'Search for DK books by keyword or ISBN',
      searchPlaceholderMobile: 'Search DK books',
      searchRecent: 'Recently searched',
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'global' })
export class GlobalStore extends Store<GlobalState> {
  constructor() {
    super(createInitialState());
  }
}
