<a
  class="book-object gtm-product-details"
  dkIsVisible
  (isVisible)="isvisible($event)"
  [routerLink]="url"
  *ngIf="bookObject"
  (click)="handleProductClick()"
>
  <div class="book-object__picture-frame" [ngClass]="{ 'book-object__picture-frame--image-loaded': isLoaded }">
    <picture *ngIf="bookObjectType === 'bookQuickSearch'">
      <source
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_100,w_81,f_auto/{{ imageFolder }}/{{ imageId }},
                      {{ imageDomain }}/q_70,c_limit,h_200,w_162,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                      {{ imageDomain }}/q_70,c_limit,h_300,w_243,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />

      <img
        alt="{{ title }}"
        (load)="imageLoaded()"
        (error)="imageFallback($event)"
        data-src="{{ imageDomain }}/q_70,c_limit,h_100,w_81,f_auto/{{ imageFolder }}/{{ imageId }}"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_200,w_162,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                   {{ imageDomain }}/q_70,c_limit,h_300,w_243,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />
    </picture>

    <picture *ngIf="bookObjectType === 'bookList'">
      <dk-icon *ngIf="preorderIcon" svgUrl="assets/images/pre-order.svg" class="preorder-icon"></dk-icon>
      <source
        media="(max-width: 47.9375em)"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_249,w_189,f_auto/{{ imageFolder }}/{{ imageId }},
                        {{ imageDomain }}/q_70,c_limit,h_498,w_378,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                        {{ imageDomain }}/q_70,c_limit,h_747,w_567,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />

      <source
        media="(min-width: 48em) and (max-width: 63.9375em)"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_180,w_137,f_auto/{{ imageFolder }}/{{ imageId }},
                        {{ imageDomain }}/q_70,c_limit,h_360,w_274,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                        {{ imageDomain }}/q_70,c_limit,h_550,w_411,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />

      <source
        media="(min-width: 64em) and (max-width: 79.9375em)"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_227,w_173,f_auto/{{ imageFolder }}/{{ imageId }},
                        {{ imageDomain }}/q_70,c_limit,h_454,w_346,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                        {{ imageDomain }}/q_70,c_limit,h_581,w_519,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />

      <source
        media="(min-width: 80em)"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_290,w_220,f_auto/{{ imageFolder }}/{{ imageId }},
                        {{ imageDomain }}/q_70,c_limit,h_580,w_440,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                        {{ imageDomain }}/q_70,c_limit,h_870,w_660,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />

      <img
        alt="{{ title }}"
        (load)="imageLoaded()"
        (error)="imageFallback($event)"
        data-src="{{ imageDomain }}/q_70,c_limit,h_290,w_220,f_auto/{{ imageFolder }}/{{ imageId }}"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_580,w_440,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                     {{ imageDomain }}/q_70,c_limit,h_870,w_660,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />
    </picture>

    <picture *ngIf="bookObjectType === 'bookArticle'">
      <source
        media="(max-width: 47.9375em)"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_150,w_235,f_auto/{{ imageFolder }}/{{ imageId }},
                          {{ imageDomain }}/q_70,c_limit,h_300,w_470,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                          {{ imageDomain }}/q_70,c_limit,h_450,w_705,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />
      <source
        media="(min-width: 48em) and (max-width: 63.9375em)"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_192,w_235,f_auto/{{ imageFolder }}/{{ imageId }},
                          {{ imageDomain }}/q_70,c_limit,h_384,w_470,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                          {{ imageDomain }}/q_70,c_limit,h_576,w_705,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />
      <source
        media="(min-width: 64em)"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_237,w_180,f_auto/{{ imageFolder }}/{{ imageId }},
                          {{ imageDomain }}/q_70,c_limit,h_474,w_360,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                          {{ imageDomain }}/q_70,c_limit,h_711,w_540,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />

      <img
        alt="{{ title }}"
        (load)="imageLoaded()"
        (error)="imageFallback($event)"
        data-src="{{ imageDomain }}/q_70,c_limit,h_236,w_180,f_auto/{{ imageFolder }}/{{ imageId }}"
        data-srcset="{{ imageDomain }}/q_70,c_limit,h_472,w_360,f_auto/{{ imageFolder }}/{{ imageId }} 2x,
                       {{ imageDomain }}/q_70,c_limit,h_708,w_540,f_auto/{{ imageFolder }}/{{ imageId }} 3x"
      />
    </picture>
  </div>
  <section [ngClass]="{ 'book-object__search-highlights': searchResults }">
    <h4 class="h__bold" [innerHTML]="highlightedTitle ? highlightedTitle : title"></h4>
    <p
      *ngIf="bookObjectType !== 'bookQuickSearch'"
      [innerHTML]="highlightedDescription ? (highlightedDescription | descriptionHighlight) : description"
    ></p>
    <div class="price" *ngIf="!(hidePrices$ | async)">{{ price | currency: currency }}</div>
    <button *ngIf="bookObjectType !== 'bookQuickSearch' && bookObjectType !== 'bookList'" class="button button--red">
      View Book
    </button>
  </section>
</a>
