<section
  class="search-bar__overlay"
  [ngClass]="{
    'search-bar__overlay--open': openQuick,
    'search-bar__overlay--home-search': pageType === 'HomepageComponent' || pageType === 'SearchComponent',
    'search-bar__overlay--product': pageType === 'ProductComponent'
  }"
  *ngIf="(labels$ | async) as labels"
>
  <article class="search-bar__quick" itemscope itemtype="http://schema.org/WebSite">
    <meta itemprop="url" content="https://www.dk.com/" />
    <form
      class="search-bar__header"
      action=""
      itemprop="potentialAction"
      itemscope
      itemtype="http://schema.org/SearchAction"
      autocomplete="off"
    >
      <button class="search-bar__close-btn" *ngIf="openQuick" (click)="closeQuickSearch()">
        <dk-icon class="dk-icon--w-20" svgUrl="assets/images/icon-close.svg"></dk-icon>
      </button>
      <meta itemprop="target" content="https://www.dk.com/{{ country }}/search/{search_term_string}" />
      <input
        itemprop="query-input"
        name="search_term_string"
        type="search"
        aria-label="Search DK books"
        [placeholder]="isDesktop ? labels.searchPlaceholderDesktop : labels.searchPlaceholderMobile"
        (click)="openQuickSearch()"
        (focus)="openQuickSearch()"
        [(ngModel)]="searchQuery"
        #quickSearch="ngModel"
        (keyup)="search(quickSearch.value)"
        (keyup.enter)="quickSearch.value === '' ? (searchQuery = null) : onEnter(quickSearch.value)"
        #searchInput
        [ngClass]="{ 'search-bar__has-clear-button': searchQuery && searchQuery.length }"
      />
      <button
        *ngIf="searchQuery && searchQuery.length"
        type="button"
        (click)="clearQuickSearch()"
        class="search-bar__clear-text"
        aria-label="clear text"
      >
        <dk-icon
          class="search-bar__clear-text-icon search-bar__round-icon dk-icon--f-white"
          svgUrl="assets/images/icon-close.svg"
        ></dk-icon>
      </button>
      <button class="search-bar__open-btn" (click)="onEnter(quickSearch.value)" aria-label="Search">
        <dk-icon
          svgUrl="assets/images/icon-search.svg"
          class="dk-icon--f-white dk-icon--w-20 dk-icon--ma"
          [ngClass]="{ 'dk-icon--f-grey': pageType === 'ProductComponent' }"
          focusable="false"
        ></dk-icon>
      </button>
    </form>

    <section
      *ngIf="searchQuery && totalResults > 3 && openQuick"
      #searchResultsSection
      class="search-bar__section search-bar__section__results h__delayed-fadein"
    >
      <footer *ngIf="isDesktop" class="h__delayed-fadein">
        <div>{{ labels.searchCTA }}</div>
      </footer>
      <h4 class="h__bold">{{ labels.searchBookTitle }}</h4>
      <dk-book-object
        class="book-quick-search"
        *ngFor="let book of resBooks"
        [bookObject]="book"
        [maxLength]="100"
        bookObjectType="bookQuickSearch"
        (click)="closeQuickSearch()"
      ></dk-book-object>
      <button class="search-bar__view-all" (click)="onEnter(quickSearch.value)">
        {{ labels.searchBookCTA + totalResults + labels.searchBookTitle.toLowerCase() }}
        <dk-icon
          svgUrl="assets/images/arrow-down.svg"
          class="dk-icon--f-white dk-icon--w-10 dk-icon--r-270deg modal-country-icon"
        ></dk-icon>
      </button>
    </section>

    <section
      *ngIf="recentKeywords.length > 0 && !searchQuery"
      class="search-bar__section search-bar__section__recent h__delayed-fadein"
    >
      <div class="h__bold search-bar__inline">{{ labels.searchRecent }}</div>
      <ul class="search-bar__recent">
        <li *ngFor="let item of recentKeywords" class="search-bar__recent-term">
          <button (click)="onEnter(item)" class="search-bar__recent-term-text">
            {{ item
            }}<dk-icon
              svgUrl="assets/images/arrow-down.svg"
              class="search-bar__recent-term-arrow dk-icon--f-black dk-icon--w-10 dk-icon--r-270deg"
            ></dk-icon>
          </button>
          <button
            (click)="removeKeyword(item)"
            [attr.aria-label]="'remove term ' + item"
            class="search-bar__recent-term-btn"
          >
            <dk-icon
              class="search-bar__recent-term-icon search-bar__round-icon dk-icon--f-black dk-icon--hover-white"
              svgUrl="assets/images/icon-close.svg"
            ></dk-icon>
          </button>
        </li>
      </ul>
      <div class="search-bar__clear">
        <button *ngIf="isDesktop" type="button" class="search-bar__clear-button" (click)="clearRecentSearch()">
          {{ labels.searchDeleteDesktopCTA }}
          <dk-icon class="search-bar__clear-icon dk-icon--f-blue" svgUrl="assets/images/icon-close.svg"></dk-icon>
        </button>
        <button *ngIf="!isDesktop" type="button" class="search-bar__clear-button" (click)="clearRecentSearch()">
          {{ labels.searchDeleteMobileCTA }}
          <dk-icon class="search-bar__clear-icon dk-icon--f-blue" svgUrl="assets/images/icon-close.svg"></dk-icon>
        </button>
        <p>{{ labels.searchHistoryNotice }}</p>
      </div>
    </section>
  </article>
  <div
    class="search-bar__clickaway"
    [ngClass]="{
      'search-bar__clickaway__active': openQuick
    }"
    (click)="closeQuickSearch()"
  ></div>
</section>
