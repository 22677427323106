import { Component, Input, OnInit } from '@angular/core';
import { ResourceGridInterface } from './resource-grid.interface';

@Component({
  selector: 'dk-resource-grid',
  templateUrl: './resource-grid.component.html',
  styleUrls: ['./resource-grid.styles.scss'],
})
export class ResourceGridComponent implements OnInit {
  resourceGridObject: ResourceGridInterface;
  @Input('grid-data') GridData: ResourceGridInterface;
  ngOnInit() {
    this.resourceGridObject = this.GridData;
  }
}
