import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from 'app/services/helper/helper.service';
import { MarketInterface, SiteWideLabelsInterface } from 'app/store/global.store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { concatMap, map, takeUntil } from 'rxjs/operators';
import { ModalService } from '../modal/state/modal.service';
import { FooterInterface } from './footer.interface';
import { FooterService } from './footer.service';

@Component({
  selector: 'dk-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<any> = new Subject();

  activemarket$: Observable<MarketInterface>;
  footer$: Observable<FooterInterface>;
  isBrowser: boolean = this.helperService.isBrowser;
  modalState: Observable<string>;
  selectedItem: string;
  urlNewsletter: string;
  /** The header's labels */
  labels$: Observable<SiteWideLabelsInterface>;

  constructor(
    private helperService: HelperService,
    private _modalService: ModalService,
    private _footerService: FooterService
  ) {}

  ngOnInit() {
    this.modalState = this._modalService.getActiveModal().pipe(takeUntil(this.unsubscribe));

    const footer$ = this._footerService.getFooterContent();

    const countries$ = footer$.pipe(
      concatMap(footer => {
        this.urlNewsletter = footer.newsletterUrl;
        return this._footerService.buildCountriesList(footer.countries);
      })
    );

    this.footer$ = combineLatest([footer$, countries$]).pipe(
      map(([footer, countries]) => {
        footer.countries = countries;
        return footer;
      })
    );

    this.labels$ = this._footerService.getLabels();

    this.activemarket$ = this._footerService.getActiveMarket();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  showModal(modalName: string) {
    this._modalService.setActiveModal(modalName);
  }

  toggleMenu(item: string) {
    if (this.selectedItem === item) {
      return (this.selectedItem = '');
    }
    this.selectedItem = item;
  }
}
