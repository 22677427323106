<div class="modal__overlay {{ modalName }}" (click)="closeModal()"></div>

<div class="modal {{ modalName }}">
  <h2 *ngIf="modalTitle">
    {{ modalTitle }}
    <button class="button close-modal" (click)="closeModal()">
      <dk-icon class="closebutton-icon dk-icon--f-grey dk-icon--h-20" svgUrl="assets/images/icon-close.svg"></dk-icon>
    </button>
  </h2>
  <div class="modal__content-container">
    <ng-content></ng-content>
  </div>
</div>
