import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { GlobalState, GlobalStore } from './global.store';

@Injectable({ providedIn: 'root' })
export class GlobalQuery extends Query<GlobalState> {
  /** Should hide prices? */
  hidePrices$ = this.select(state => {
    if (state.activeMarket) {
      return state.activeMarket.hidePrice;
    }
    return true;
  });

  getlanguageCode$ = this.select(state => {
    if (state.activeMarket) {
      return state.activeMarket.defaultLanguage;
    }
  });

  constructor(protected store: GlobalStore) {
    super(store);
  }
}
