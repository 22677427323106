<div class="recently-viewed" *ngIf="{ books: books$ | async, label: labels$ | async } as data">
  <ng-container *ngIf="data.books && data.books.length > 0">
    <div class="recently-viewed__title">{{ data.label }}</div>
    <div class="recently-viewed__scroller">
      <div class="recently-viewed__body" [ngClass]="{ 'recently-viewed__body-wide': data.books.length > 4 }">
        <div class="recently-viewed__book" *ngFor="let book of data.books">
          <a [routerLink]="[book.url]">
            <img
              alt="{{ book.title }}"
              class="recently-viewed__book-image"
              (error)="imageError(book.url)"
              data-src="{{ book.formats[0].cover.cdn }}/q_70,c_limit,h_200,w_150,f_auto/{{
                book.formats[0].cover.namespace
              }}/{{ book.formats[0].cover.dir + '/' + book.formats[0].cover.filename }}"
              data-srcset="{{ book.formats[0].cover.cdn }}/q_70,c_limit,h_400,w_300,f_auto//{{
                book.formats[0].cover.namespace
              }}/{{ book.formats[0].cover.dir + '/' + book.formats[0].cover.filename }} 2x,
              {{ book.formats[0].cover.cdn }}/q_70,c_limit,h_600,w_450,f_auto//{{ book.formats[0].cover.namespace }}/{{
                book.formats[0].cover.dir + '/' + book.formats[0].cover.filename
              }} 3x"
            />
          </a>
          <div class="recently-viewed__delete-box" (click)="removeBook(book.url)">
            <svg class="recently-viewed__delete-icon" width="32" height="32">
              <path d="M 10 10 L 22 22 M 10 22 L 22 10" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
