import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HelperService } from './services/helper/helper.service';
import { PreviousPageScrollService } from './services/scroll/previousPageScroll.service';
import { ScrollService } from './services/scroll/scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [PreviousPageScrollService],
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  private isBrowser: boolean = this.helperService.isBrowser;

  /** Current page url */
  private _currentPage: string;

  constructor(
    private helperService: HelperService,
    private previousPageScrollService: PreviousPageScrollService,
    private router: Router,
    private scrollService: ScrollService
  ) {}

  ngOnInit() {
    if (this.isBrowser) {
      this.routerSubscription = this.router.events.subscribe(evt => {
        if (evt instanceof NavigationEnd) {
          this.scrollService.scrollToTop();
          this._currentPage = evt.url || evt.urlAfterRedirects;
          this.previousPageScrollService.pagePosition$
            .pipe(
              map(event => {
                event.restore = false;
                // if the current url matches the previous url then restore scroll depth
                if (
                  this._currentPage === event.previousPage &&
                  this.previousPageScrollService.pagesToRestoreScroll.test(this._currentPage.split('/')[2]) &&
                  !this.previousPageScrollService.pagesToRestoreScroll.test(event.currentPage.split('/')[2])
                ) {
                  event.restore = true;
                }
                event.previousPage = event.currentPage;
                event.currentPage = this._currentPage;
              })
            )
            .subscribe();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.previousPageScrollService.pagePosition$) {
      this.previousPageScrollService.pagePosition$.unsubscribe();
    }
  }
}
