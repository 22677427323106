import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { HelperService } from '../services/helper/helper.service';

@Directive({
  selector: '[dkIsVisible]',
})
export class IsVisibleDirective {
  @Output() isVisible: EventEmitter<boolean> = new EventEmitter();

  private _observe(element) {
    if ('IntersectionObserver' in window) {
      const intersectionOptions: IntersectionObserverInit = {
        rootMargin: '0%',
      };

      const intersectionCallback: IntersectionObserverCallback = (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) => {
        for (const el of entries) {
          if (el.isIntersecting) {
            this.isVisible.emit(el.isIntersecting);
            observer.disconnect();
          }
        }
      };

      const intersectionObserver = new IntersectionObserver(intersectionCallback, intersectionOptions);

      intersectionObserver.observe(element);
    } else {
      this.isVisible.emit(true);
    }
  }

  constructor(private element: ElementRef, private helperService: HelperService) {
    if (this.helperService.isBrowser) {
      this._observe(this.element.nativeElement);
    } else {
      this.isVisible.emit(false);
    }
  }
}
