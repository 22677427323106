import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { merge as observableMerge, Observable, of as observableOf, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

/**
 * This service provides information about the viewport
 */
export class ViewportService {
  private resizeSubject = new Subject<Window>();
  private isBrowser: boolean = isPlatformBrowser(this.platformId);
  private _matchMedia: (query: string) => MediaQueryList;

  private onResizeHandler(event: UIEvent) {
    this.resizeSubject.next(<Window>event.target);
  }

  private onResize(): Observable<Window> {
    return this.resizeSubject.pipe(
      // Delays emiting values by 250ms so that we dont hammer the service
      debounceTime(250)
    );
  }

  private noopMatchMedia(query: string): any {
    return {
      matches: query === 'all' || query === '',
      media: query,
      addListener: () => {},
      removeListener: () => {},
    };
  }

  /**
   * Evaluates the given media query and returns the native MediaQueryList from which results
   * can be retrieved.
   * Confirms the layout engine will trigger for the selector query provided and returns the
   * MediaQueryList for the query provided.
   */
  private matchMedia(query: string): MediaQueryList {
    return this._matchMedia(query);
  }

  constructor(private eventManager: EventManager, @Inject(PLATFORM_ID) private platformId: Object) {
    this.eventManager.addGlobalEventListener('window', 'resize', this.onResizeHandler.bind(this));
    this._matchMedia =
      this.isBrowser && window.matchMedia
        ? // matchMedia is bound to the window scope intentionally as it is an illegal invocation to
          // call it from a different scope.
          window.matchMedia.bind(window)
        : this.noopMatchMedia;
  }

  /**
   * Returns a boolean if the viewport is mobile (< 768px)
   */
  get isMobile(): Observable<boolean> {
    // Gets the window width on page load
    const initial = observableOf(this.matchMedia('(max-width: 47.9375rem)').matches ? true : false);
    // Gets the window width on page resize
    const resize = this.onResize().pipe(
      map(viewport => {
        return viewport.matchMedia('(max-width: 47.9375rem)').matches ? true : false;
      })
    );
    // merge both observables and fires only when values are different
    return observableMerge(resize, initial).pipe(distinctUntilChanged());
  }

  /**
   * Returns a boolean if the viewport is tablet portrait (>= 768px and < 1024)
   */
  get isTabletPortrait(): Observable<boolean> {
    const initial = observableOf(
      this.matchMedia('(min-width: 48rem) and (max-width: 63.9375rem)').matches ? true : false
    );
    const resize = this.onResize().pipe(
      map(viewport => {
        return viewport.matchMedia('(min-width: 48rem) and (max-width: 63.9375rem)').matches ? true : false;
      })
    );
    return observableMerge(resize, initial).pipe(distinctUntilChanged());
  }

  /**
   * Returns a boolean if the viewport is tablet portrait or bigger (>= 768px)
   */
  get isTabletPortraitOrBigger(): Observable<boolean> {
    const initial = observableOf(this.matchMedia('(min-width: 48rem)').matches ? true : false);
    const resize = this.onResize().pipe(
      map(viewport => {
        return viewport.matchMedia('(min-width: 48rem)').matches ? true : false;
      })
    );
    return observableMerge(resize, initial).pipe(distinctUntilChanged());
  }

  /**
   * Returns a boolean if the viewport is tablet landscape (>= 1024 and < 1280)
   */
  get isTabletLandscape(): Observable<boolean> {
    const initial = observableOf(
      this.matchMedia('(min-width: 64rem) and (max-width: 79.9375rem)').matches ? true : false
    );
    const resize = this.onResize().pipe(
      map(viewport => {
        return viewport.matchMedia('(min-width: 64rem) and (max-width: 79.9375rem)').matches ? true : false;
      })
    );
    return observableMerge(resize, initial).pipe(distinctUntilChanged());
  }

  /**
   * Returns a boolean if the viewport is tablet landscape or bigger (>= 1024)
   */
  get isTabletLandscapeOrBigger(): Observable<boolean> {
    const initial = observableOf(this.matchMedia('(min-width: 64rem)').matches ? true : false);
    const resize = this.onResize().pipe(
      map(viewport => {
        return viewport.matchMedia('(min-width: 64rem)').matches ? true : false;
      })
    );
    return observableMerge(resize, initial).pipe(distinctUntilChanged());
  }

  /**
   * Returns a boolean if the viewport is desktop (>= 1280)
   */
  get isDesktop(): Observable<boolean> {
    const initial = observableOf(this.matchMedia('(min-width: 80rem)').matches ? true : false);
    const resize = this.onResize().pipe(
      map(viewport => {
        return viewport.matchMedia('(min-width: 48rem)').matches ? true : false;
      })
    );
    return observableMerge(resize, initial).pipe(distinctUntilChanged());
  }

  get viewportWidth(): number {
    return document.documentElement.clientWidth;
  }

  get viewportHeight(): number {
    return document.documentElement.clientHeight;
  }
}
