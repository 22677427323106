import { Component, Input, OnInit } from '@angular/core';
import { CountriesInterface } from 'app/components/molecules/footer/footer.interface';

@Component({
  selector: 'dk-change-country',
  templateUrl: './change-country.component.html',
  styleUrls: ['./change-country.component.scss'],
})
export class ChangeCountryComponent implements OnInit {
  @Input() countryData: CountriesInterface[];
  countryList: CountriesInterface[];
  constructor() {}

  ngOnInit() {
    this.countryList = this.countryData;
  }
}
