import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  manualLoader = new Subject<boolean>();

  constructor() {}

  loading(show: boolean): void {
    this.manualLoader.next(show);
  }
}
