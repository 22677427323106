import { DkPictureInterface } from 'app/components/atoms/dk-picture/dk-picture.interface';
import { DkPictureService } from 'app/components/atoms/dk-picture/dk-picture.service';
import { SpreadInterface } from 'app/components/molecules/gallery-mega/gallery-mega.interface';
import { FormatsInterface, ProductInterface } from '../product.interface';

const pictureService = new DkPictureService();

function _buildCoverImage(format: FormatsInterface): DkPictureInterface {
  let imageId: string;
  if (format.cover) {
    imageId = `${format.cover.namespace}/${format.cover.dir}/${format.cover.filename}`;
  } else {
    imageId = 'dk-core-nonprod/static/dk-2020-no-cover.jpg';
  }
  const cover: DkPictureInterface = {
    imageId: imageId,
    altText: `${format.format} cover of ${format.title}`,
    title: format.title,
    mediaQueries: [
      {
        type: 'desktop',
        modifiers: 'c_limit,f_auto',
        dimensions: {
          heigth: 650,
          width: 580,
        },
      },
      {
        type: 'tablet-landscape',
        modifiers: 'c_limit,f_auto',
        dimensions: {
          heigth: 650,
          width: 460,
        },
      },
      {
        type: 'tablet-portrait',
        modifiers: 'c_limit,f_auto',
        dimensions: {
          heigth: 500,
          width: 410,
        },
      },
      {
        type: 'mobile',
        modifiers: 'c_limit,f_auto',
        dimensions: {
          heigth: 500,
          width: 280,
        },
      },
    ],
  };

  cover.mediaQueries = pictureService.buildMediaQueries(cover.mediaQueries, true);

  return cover;
}

function _buildThumbnailImage(spread: SpreadInterface, title: string, i: number): DkPictureInterface {
  const image: DkPictureInterface = {
    imageId: `${spread.namespace}/${spread.dir}/${spread.filename}`,
    altText: `Thumbnail image of ${title} - ${i}`,
    title: `${title} - thumbnail of spread image ${i}`,
    mediaQueries: [
      {
        type: 'desktop',
        modifiers: 'q_75,c_fill,f_auto',
        dimensions: {
          heigth: 67,
          width: 67,
        },
      },
      {
        type: 'tablet-landscape',
        modifiers: 'q_75,c_fill,f_auto',
        dimensions: {
          heigth: 52,
          width: 52,
        },
      },
      {
        type: 'tablet-portrait',
        modifiers: 'q_75,c_fill,f_auto',
        dimensions: {
          heigth: 45,
          width: 45,
        },
      },
      {
        type: 'mobile',
        modifiers: 'q_75,c_fill,f_auto',
        dimensions: {
          heigth: 52,
          width: 52,
        },
      },
    ],
  };

  image.mediaQueries = pictureService.buildMediaQueries(image.mediaQueries, true);

  return image;
}

function _buildThumbnails(spreads: SpreadInterface[], title: string): DkPictureInterface[] {
  const thumbnails: DkPictureInterface[] = [];
  let i = 1;
  for (const spread of spreads) {
    thumbnails.push(_buildThumbnailImage(spread, title, i));
    i++;
  }

  return thumbnails;
}

function _setPreOrder(pubDate: Date): boolean {
  return new Date(pubDate) > new Date();
}

function _updateFormatData(formats: FormatsInterface[], title: string): FormatsInterface[] {
  const filteredFormats = formats.filter(format => format.retailers.length);
  for (const format of filteredFormats) {
    format.title = title;
    format.preOrder = _setPreOrder(format.pubDate);
    format._cover = _buildCoverImage(format);
    format._thumbnails = _buildThumbnails(format.spreads, format.title);
  }
  return filteredFormats;
}

/**
 * A factory function that creates Product
 */
export function createProduct(params: Partial<ProductInterface>): ProductInterface {
  return {
    ...params,
    formats: _updateFormatData(params.formats, params.title),
  } as ProductInterface;
}
