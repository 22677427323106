import { Injectable } from '@angular/core';
import { del, set, Store } from 'idb-keyval';
import { Observable } from 'rxjs';
import { FormatsInterface, ProductInterface } from '../product.interface';
import { createProduct } from './product.model';
import { FormatQuery, ProductQuery } from './product.query';
import { FormatStore, ProductStore } from './product.store';

const productStore = new Store('dk-idb', 'product-store');

@Injectable({
  providedIn: 'root',
})
export class ProductStoreService {
  constructor(
    private _productStore: ProductStore,
    private _productQuery: ProductQuery,
    private _formatStore: FormatStore,
    private _formatQuery: FormatQuery
  ) {}

  /**
   * Adds a product to the store, marks it as active and then adds it to IDB cache.
   * @param product The product to add to store
   */
  addProductToStore(product: ProductInterface) {
    const _product = createProduct(product);
    this._productStore.add(_product);
    this._productStore.setActive(_product.url);
    // Add product to IDB
    set(_product.url, _product, productStore);
  }

  /**
   * Removes a book from the Product Store and from IDB
   *
   * The product page subscribes the the Product store, so we can't remove an active product
   * @param url The url of the book we want to remove
   */
  deleteBookfromStore(url: string) {
    const currentURL = this._productQuery.getActiveId();
    if (currentURL !== url) {
      this._productStore.remove(url);
    }
    return del(url, productStore);
  }

  hasProduct(url: string): boolean {
    return this._productQuery.hasEntity(url);
  }

  setProductActive(url: string) {
    this._productStore.setActive(url);
  }

  selectActiveFormat(): Observable<FormatsInterface> {
    return this._formatQuery.selectActive();
  }

  getActiveFormat(): FormatsInterface {
    return this._formatQuery.getActive();
  }

  selectActiveProduct(): Observable<ProductInterface> {
    return this._productQuery.selectActive();
  }

  getActiveProduct(): ProductInterface {
    return this._productQuery.getActive();
  }

  /**
   * Returns the most recent edition of a specific format.
   * For example, if formatQuery === 'Hardback', the function will return the latest available Hardback.
   * @param formatQuery string e.g. 'Hardback' or 'Paperback'
   * @param availability Are we looking for currently available formats or preorder formats?
   */
  getMostRecentFormat(formatQuery: String, availability: 'available' | 'preorder') {
    const products = this._productQuery.getActive();
    const editions = products.formats;
    /** Number of milliseconds since Unix Epoch i.e. January 1 1970 00:00:00*/
    const currentTimeUnix = Date.now();
    /**
     * available editions
     * if availability === 'available' then the filter will return currently available editions
     * if availability === 'preorder' then the filter will return formats that are available to preorder
     */
    const availableEditions = editions.filter(edition => {
      /** Publish Date in milliseconds since Unix Epoch */
      const pubDateParsed = Date.parse(edition.pubDate.toString());
      if (availability === 'available') {
        /** true if the edition is available */
        return pubDateParsed <= currentTimeUnix && edition.format === formatQuery;
      } else {
        /** true if the edition is a preorder */
        return pubDateParsed > currentTimeUnix && edition.format === formatQuery;
      }
    });
    // Sorts availableEditions in descending order with newer releases first
    // Therefore, availableEditions[0] would be the newest avaiable edition of the product
    availableEditions.sort((editionA, editionB) => {
      const dateA = Date.parse(editionA.pubDate.toString());
      const dateB = Date.parse(editionB.pubDate.toString());
      if (dateA > dateB) {
        return -1;
      } else if (dateA === dateB) {
        return 0;
      } else if (dateA < dateB) {
        return 1;
      }
    });
    return availableEditions[0];
  }

  /** Returns an array of formats with the most recent available format for each type.
   * if availability === 'available' then a list with the latest edition thats available for a format will be returned
   * else a list of all the latest preorders will be returned
   */
  filterFormats(availability: 'available' | 'preorder') {
    const products = this._productQuery.getActive();
    const formats = products.formats;
    const availableFormatTypes = formats.map(format => format.format);
    const formatSet: Set<string> = new Set();
    availableFormatTypes.forEach(format => formatSet.add(format));
    const filteredFormats = Array.from(formatSet)
      .map(format => this.getMostRecentFormat(format, availability))
      .filter(format => {
        if (format) {
          return true;
        }
      });
    return filteredFormats;
  }

  setActiveFormat(isbn13?: string) {
    const availableFormats = this._formatStore.getValue().availableFormats;
    const formats = this._formatQuery.getEntity(isbn13);
    if (isbn13 && isbn13.toString().startsWith('978')) {
      // TODO: API should return `isbn` field as a string
      if (formats) {
        this._formatStore.setActive(isbn13);
        this._setActiveAvailabilityGroupByIsbn(isbn13);
      } else {
        this._formatStore.setActive(availableFormats[0].isbn13);
        this._setActiveAvailabilityGroupByIsbn(availableFormats[0].isbn13);
      }
    } else {
      this._formatStore.setActive(availableFormats[0].isbn13);
      this._setActiveAvailabilityGroupByIsbn(availableFormats[0].isbn13);
    }
  }
  /** Sets the availability group i.e. 'available' or 'preorder' based on the isbn13 value of an edition.
   * i.e. if the edition is a preorder, then the availability group will be 'preorder' and vice versa
   */
  private _setActiveAvailabilityGroupByIsbn(isbn13?: string) {
    if (isbn13) {
      const availableFormats = this._formatStore.getValue().availableFormats;
      const hasIsnb = availableFormats.filter(format => format.isbn13 === isbn13);
      if (hasIsnb.length) {
        this._formatStore.update({ activeVisibilityGroup: 'available' });
      } else {
        this._formatStore.update({ activeVisibilityGroup: 'preorder' });
      }
    } else {
      this._formatStore.update({ activeVisibilityGroup: 'available' });
    }
  }

  /** Updates the availability group and the visible formats */
  updateActiveAvailabilityGroup(availability: 'available' | 'preorder') {
    this._formatStore.update({ activeVisibilityGroup: availability });
    let format: FormatsInterface[];
    if (availability === 'available') {
      format = this._formatStore.getValue().availableFormats;
    } else {
      format = this._formatStore.getValue().preorderFormats;
    }
    this._formatStore.setActive(format[0].isbn13);
  }

  selectActiveAvailabilityGroup(): Observable<'available' | 'preorder'> {
    return this._formatQuery.select(store => store.activeVisibilityGroup);
  }

  selectVisibleFormats(availability: 'available' | 'preorder'): Observable<FormatsInterface[]> {
    if (availability === 'available') {
      return this._formatQuery.select(store => store.availableFormats);
    } else {
      return this._formatQuery.select(store => store.preorderFormats);
    }
  }

  selectProductByURL(url: string) {
    return this._productQuery.selectEntity(url);
  }

  selectProductsByCountry(country: string, limit?: number) {
    return this._productQuery.selectAll({
      limitTo: limit ? limit : undefined,
      filterBy: book => book.url.includes(`/${country}/`),
    });
  }

  updateFormatStore() {
    const product = this._productQuery.getActive();
    const formats = product.formats;
    const availableFormats = this.filterFormats('available');
    const preorderFormats = this.filterFormats('preorder');
    this._formatStore.set(formats);
    this._formatStore.update({ availableFormats });
    this._formatStore.update({ preorderFormats });
  }

  /**
   * Updates the product store record
   * @param product The product to be updated
   */
  updateProductInStore(product: ProductInterface) {
    const _product = createProduct(product);
    this._productStore.update(_product.url, _product);
    this._formatStore.update(_product.formats);
    set(_product.url, _product, productStore);
  }
}
