import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BookObjectInterface } from 'app/components/molecules/book-object/book-object.interface';
import { SolrDocsInterface } from 'app/components/pages/search/search.interface';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  /**
   * Detects if applications is running in a browser
   * @returns {boolean} - Returns true if applications is running a browser
   * @memberof HelperService
   */
  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  extractCountryFromUrl(url: string): string {
    return url.split('/')[1];
  }

  /**
   * Builds an SEO friendly string to use with images
   * @param {string} title
   * @returns {string}
   * @memberof HelperService
   */
  buildSeoImageName(title: string): string {
    return `dk_${title.replace(/ |[.]/gi, '_')}`;
  }

  /* tslint:disable:max-line-length */
  /**
   * Returns an image ID from full a cloudinary URL
   * @param {string} url - Full Couldinary URL
   * ie: https://res.cloudinary.com/dk-hub/image/upload/c_pad,g_center,h_160,w_145,f_auto/v1/DK/e3c342428c05478f96c8f54f75abd852/c46d6875bada4f468b96030c4b92e672.jpg
   * @returns {string} - Return an image ID, ie : e3c342428c05478f96c8f54f75abd852/c46d6875bada4f468b96030c4b92e672.jpg
   * @memberof HelperService
   */
  /* tslint:enable:max-line-length */
  getImageIdFromUrl(url: string): string {
    if (url) {
      const dkPos = url.indexOf('DK');
      return url.slice(dkPos + 2);
    } else {
      return 'static/no-cover';
    }
  }

  /**
   * Transforms the SOLR docs response into BookObject format
   * Needed while we dont change SOLR properties
   * @param {SolrDocsInterface[]} docs - The SOLR docs response
   * @returns {BookObjectInterface[]} - An Array of book objects
   * @memberof SearchService
   */
  transformBookObject(docs: SolrDocsInterface[]): BookObjectInterface[] {
    const books: BookObjectInterface[] = [];
    for (const doc of docs) {
      if (doc.type === 'Product') {
        try {
          const bookObject: BookObjectInterface = {
            id: doc.id,
            description: doc.description,
            mainImageUrl: this.getImageIdFromUrl(doc.mainImageUrl),
            url: `/${doc.url}/`,
            displayName: doc.displayName,
            price: doc.price,
            type: 'book',
            currency: doc.currency,
            publishDate: doc.publishDate,
            highlightedName: doc.highlightedName,
            highlightedDescription: doc.highlightedDescription,
          };
          books.push(bookObject);
        } catch (err) {
          console.error(
            'Book did not pass validaton. Will show the book below, then the error, and then continue processing...',
            doc,
            err
          );
        }
      }
    }
    return books;
  }
  /**
   * Returns a string without HTML tags, apart from `<em>` If there is a max length
   * then return substring
   */
  stripHtmlTag(text: string, maxLength?: number): string {
    if (text) {
      /** replace p tags in the description with spaces, as there are 'loose' tags.
       * Get rid of all other html except `<em>` */
      let description = text.replace(/(<p><\/p>|<\/p>)/gi, ' ').replace(/(<(?!(\/em)|(em)))([^>]+>)/gi, '');
      if (maxLength) {
        description = description.substring(0, maxLength) + '...';
      }
      return description;
    } else {
      return null;
    }
  }

  /**
   * Returns a 3D array sorted alphabetically. The key to compare must be provided.
   * @param arr
   * @param key
   */
  sortArrayOfObjects<T, K extends Extract<keyof T, string>>(arr: T[], key: K) {
    arr.sort((a, b) => {
      const objA = a[key].toString().toUpperCase();
      const objB = b[key].toString().toUpperCase();
      if (objA < objB) {
        return -1;
      }
      if (objA > objB) {
        return 1;
      }
      return 0;
    });
    return arr;
  }

  /* Filter for valid items that can occur inside a listing.
     The function accepts an item Object and returns:
     - true,  if item is validItem
     - false, if item is not valid
  */
  public validListingItem(item: any): boolean {
    if (typeof item !== 'object') {
      return false;
    }
    if (typeof item.type !== 'string') {
      return false;
    }

    // Remove all carousels which do not have books array, or its length is 0
    if (item.type === 'carousel' && !(Array.isArray(item.books) && item.books.length)) {
      return false;
    }

    return true; // Execpt all other items
  }

  // Take a string 'str' and remove specified characters 'chars' ['a','b','c'] from both start and end of string 'str'
  trimChars(str: string, chars: Array<string>): string {
    if (typeof str !== 'string' || !Array.isArray(chars)) {
      return null;
    }
    let start: number; // Where the main content of the string starts (without specified characters)
    let stop: number; // Where the main content of the string stops
    for (start = 0; start < str.length; start++) {
      if (chars.indexOf(str[start]) === -1) {
        break;
      }
    }
    for (stop = str.length - 1; stop >= start; stop--) {
      if (chars.indexOf(str[stop]) === -1) {
        break;
      }
    }

    return str.substring(start, stop + 1);
  }
}
