import { Injectable } from '@angular/core';
import { GlobalStoreService } from 'app/store/global.service';
import { SiteWideLabelsInterface } from 'app/store/global.store';
import { Observable, Subject } from 'rxjs';
import { MailmanService } from '../../../services/mailman/mailman.service';
import { PageHeaderInterface, SubNavInterface } from './page-header.interface';

@Injectable({
  providedIn: 'root',
})
export class PageHeaderService {
  topCategories = new Subject<string[]>();
  hasBanner = new Subject<boolean>();

  constructor(private _mailmanService: MailmanService, private _globalStoreService: GlobalStoreService) {}

  announceCategories(categories: Array<SubNavInterface>): void {
    this.topCategories.next(categories[0].subTitles.map(cat => cat.title));
  }

  getHeaderContent(country: string): Observable<PageHeaderInterface> {
    return this._mailmanService.getApi<PageHeaderInterface>(`${country}/header`);
  }

  /**
   * Returns the labels for the website
   */
  getLabels(): Observable<SiteWideLabelsInterface> {
    return this._globalStoreService.selectMarketLabels();
  }
}
