import { Component, Input, OnChanges, OnDestroy, SimpleChange } from '@angular/core';
import { environment } from 'environments/environment';

import { DkIconService } from './dk-icon.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dk-icon',
  templateUrl: './dk-icon.component.html',
  styleUrls: ['./dk-icon.component.scss'],
  providers: [DkIconService],
})
export class DkIconComponent implements OnChanges, OnDestroy {
  @Input() svgUrl: string;
  @Input() externalSvgUrl: string;

  private iconSubscription: Subscription;

  constructor(private dkIconService: DkIconService) {}

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    const changedInputs = Object.keys(changes);
    // Only update the inline SVG icon if the inputs changed, to avoid unnecessary DOM operations.
    if (changedInputs.indexOf('svgUrl') !== -1) {
      let url: string;
      if (environment.production) {
        url = environment.feUrl + '/' + this.svgUrl;
      } else {
        url = this.svgUrl;
      }
      this.iconSubscription = this.dkIconService.getIconFromUrl(url).subscribe(icon => {
        if (icon) {
          this.dkIconService.setSvgElement(icon);
        }
      });
    }
    if (changedInputs.indexOf('externalSvgUrl') !== -1) {
      this.iconSubscription = this.dkIconService.getIconFromUrl(this.externalSvgUrl).subscribe(icon => {
        if (icon) {
          this.dkIconService.setSvgElement(icon);
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.iconSubscription) {
      this.iconSubscription.unsubscribe();
    } else {
      console.log('nothing to unsubscribe');
    }
  }
}
