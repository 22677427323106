import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalService } from './state/modal.service';

@Component({
  selector: 'dk-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnDestroy, OnInit {
  @Input() modalName: string;
  @Input() modalTitle: string;

  private routerSubscription: Subscription;

  constructor(private _modalService: ModalService, private _router: Router) {
    this.routerSubscription = this._router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.closeModal();
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  closeModal() {
    this._modalService.closeActiveModal();
  }
}
