import { Injectable } from '@angular/core';
import { ProductInterface } from 'app/components/pages/product/product.interface';
import { ProductStoreService } from 'app/components/pages/product/state/product.service.store';
import { GlobalStoreService } from 'app/store/global.service';
import { Store } from 'idb-keyval';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

const productStore = new Store('dk-idb', 'product-store');

@Injectable()
export class RecentlyViewedService {
  constructor(private _productStoreService: ProductStoreService, private _globalStoreService: GlobalStoreService) {}

  /**
   * Removes a book from the Recently Viewed books list
   * @param url The url of the book we want to remove
   */
  deleteBook(url: string) {
    return this._productStoreService.deleteBookfromStore(url);
  }

  /**
   * Selects the last 12 books visited in a speciffic market.
   * We get the code from the Global Store and pass use it to query the Product Store
   */
  selectRecentProducts(): Observable<ProductInterface[]> {
    return this._globalStoreService.selectActiveMarket().pipe(
      mergeMap(({ code }) => {
        return this._productStoreService.selectProductsByCountry(code, 12);
      }),
      map(books => books.reverse())
    );
  }

  selectLabels(): Observable<string> {
    return this._globalStoreService.selectMarketLabels().pipe(map(({ recentlyViewed }) => recentlyViewed));
  }
}
