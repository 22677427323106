import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';

import { LoadingService } from './loading.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dk-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
  autoLoader: boolean; // Automatic loader. Displayed automatically during page navigation
  manualLoader: boolean; // Manual loader. Can be displayed on request, e.g. during API calls
  unsubscribeAll = new Subject<void>();

  constructor(private _router: Router, private loadingService: LoadingService) {
    _router.events.pipe(takeUntil(this.unsubscribeAll)).subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  ngOnInit() {
    this.loadingService.manualLoader.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: val => {
        this.manualLoader = val;
      },
      error: () => {
        this.manualLoader = false;
      },
      complete: () => {
        this.manualLoader = false;
      },
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  navigationInterceptor(event: RouterEvent) {
    if (event instanceof NavigationStart) {
      this.autoLoader = true;
    }
    if (event instanceof NavigationEnd) {
      this.autoLoader = false;
    }
  }
}
