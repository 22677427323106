import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  writeObject(key: string, value: any): boolean {
    try {
      window.localStorage[key] = JSON.stringify(value);
      return true;
    } catch (error) {
      console.error(`Local Storage Service: error writing ${key},  details: ${error}`);
      return false;
    }
  }

  readObject(key: string): any {
    try {
      const reply = window.localStorage[key];
      if (reply) {
        return JSON.parse(reply);
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Local Storage Service: error reading ${key},  details: ${error}`);
      return null;
    }
  }
}
