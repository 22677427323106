<div class="resource-block">
  <div *ngIf="blockData.buttons.length > 0" class="dark-cover">
    <div class="buttons">
      <div class="button-container" *ngFor="let button of blockData.buttons">
        <a [href]="button.url" target="_blank" rel="noopener noreferrer"
          ><button>{{ button.text }}</button></a
        >
      </div>
    </div>
  </div>
  <div *ngIf="blockData.thumbnail" class="picture-div" [style.background-color]="blockData.thumbnail_color">
    <img [src]="blockData.thumbnail.path" [alt]="blockData.thumbnail.alt" />
  </div>
  <div class="block-text">
    <p *ngIf="blockData.resource_type" class="resource-type">{{ blockData.resource_type }}</p>
    <p *ngIf="blockData.title" class="title">{{ blockData.title }}</p>
    <p *ngIf="blockData.description" class="description" [innerHTML]="description"></p>
    <p *ngIf="blockData.age_category" class="age-category">
      <strong>{{ ageCategory.ageLabel }} </strong><label>{{ ageCategory.ageGroup }}</label>
    </p>
  </div>
</div>
