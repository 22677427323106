import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

declare var Hammer: any;

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      // touchAction: 'auto',
      recognizers: [
        [
          Hammer.Pan,
          {
            direction: Hammer.DIRECTION_ALL,
          },
        ],
      ],
    });
    return mc;
  }
}
