import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'descriptionHighlight',
})
export class DescriptionHighlightPipe implements PipeTransform {
  transform(value: string): string {
    const firstEm = value.indexOf('<em>'),
      secondEm = value.indexOf('</em>'),
      startWords = value.slice(0, firstEm).split(' '),
      endWords = value.slice(secondEm).split(' ');
    let start = startWords.slice(startWords.length - 6 > -1 ? startWords.length - 6 : 0);
    const end = endWords.slice(0, 6 + (6 - start.length));
    start = startWords.slice(startWords.length - (6 + (6 - end.length)));
    let description = [start.join(' '), value.slice(firstEm, secondEm + 5), end.join(' ')].join(' ');
    if (startWords.length !== start.length) {
      description = '...' + description;
    }
    if (endWords.length !== end.length) {
      description = description + '...';
    }
    return description;
  }
}
