import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteWideLabelsInterface } from 'app/store/global.store';
import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PageHeaderObservableInterface } from './page-header.interface';
import { PageHeaderService } from './page-header.service';

@Component({
  selector: 'dk-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<any> = new Subject();
  /** The observable containing the data for the page header, such as categories and promotions */
  pageHeader$: Observable<PageHeaderObservableInterface>;

  /** The header's labels */
  labels$: Observable<SiteWideLabelsInterface>;

  cookie: any;
  banner: any;
  country: string;
  isLoading = true;
  showExtendedCategories: boolean;
  showNav: boolean;
  /** Is the search bar active? */
  searchActive: boolean;

  constructor(private route: ActivatedRoute, private _headerService: PageHeaderService) {
    this.showNav = false;
  }

  private emitCookie(data) {
    return of(data);
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => (this.country = params.get('country')));

    this.labels$ = this._headerService.getLabels();

    this.pageHeader$ = this._headerService.getHeaderContent(this.country).pipe(
      map(res => {
        this._headerService.announceCategories(res.subnav);
        this.emitCookie(res['cookie']).subscribe(data => {
          this.cookie = data;
        });
        if (res['banner'].position) {
          this.banner = res['banner'];
          if (this.banner && this.banner.position === 'bottom') {
            this._headerService.hasBanner.next(true);
          }
        }

        this.isLoading = false;
        return {
          topPromo: res.topnav.topromo.slice(0, 3),
          topCat: res.topnav.topcat.slice(0, 6),
          categories: res.subnav,
          hideHeader: res.hideHeader,
        };
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  toggleExtendedCategories(): void {
    this.showExtendedCategories = !this.showExtendedCategories;
  }

  openNav(): void {
    this.showNav = true;
    this.showExtendedCategories = false;
  }

  closeNav(): void {
    this.showNav = false;
    this.showExtendedCategories = false;
  }

  toggleNav(): void {
    this.showNav = !this.showNav;
    this.showExtendedCategories = false;
  }

  /** Is the search bar active? */
  isSearchActive($event: boolean): void {
    this.searchActive = $event;
  }
}
