import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HelperService } from 'app/services/helper/helper.service';
import { GlobalQuery } from 'app/store/global.query';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BookObjectInterface, BookObjectType } from './book-object.interface';

@Component({
  selector: 'dk-book-object',
  templateUrl: './book-object.component.html',
  styleUrls: ['./book-object.component.scss'],
})
export class BookObjectComponent implements OnInit, OnChanges {
  /**
   * The book object
   * @type {BookObjectInterface}
   * @memberof BookObjectComponent
   */
  @Input() bookObject: BookObjectInterface;

  /**
   * The maximum description length
   * @type {number}
   * @memberof BookObjectComponent
   */
  @Input() maxLength: number;

  /**
   * The book object type, possible values:
   * - `bookQuickSearch`
   * - `bookList`
   * - `bookArticle`
   * @type {string}
   * @memberof BookObjectComponent
   */
  @Input() bookObjectType: BookObjectType;

  /**
   * Is this on the search results page?
   * @type {boolean}
   * @memberof BookObjectComponent
   */
  @Input('search-results') searchResults: boolean;

  /**
   * If this is not yet published should we display a preorder icon?
   * @type {boolean}
   * @memberof BookObjectComponent
   */
  @Input('check-preorder') checkPreorder: boolean;

  /** emits event to `BookCarousel` component for GA event */
  @Output() sendGAEvent: EventEmitter<any> = new EventEmitter();

  /** The `seoTitle` is an addon that appends the book's title to the image URL*/
  private seoTitle: string;

  /** The `author` name, if available, are used on quick search only */
  author: string;
  /** The `currency` formatted as a ISO 4217 code, is used with Angular's own currency pipe */
  currency: string;
  /** The `description` trimmed down to the length defined in the `maxLength` input */
  description: string;
  /** The available `formats` are used on quick search only */
  formats: string;
  /** The hostname for the images CDN */
  imageDomain = environment.imageServerSeoUrl;
  /** image root folder on cloudinary */
  imageFolder = environment.dkCloudinaryRoot;
  /** The `imageId` is used to fecth the image from the CDN */
  imageId: string;
  /** Toggles the css class `book-object__picture-frame--image-loaded` */
  isLoaded: boolean;
  /** The `price` of the book, should be a numerical value */
  price: number;
  /** The book's `title` */
  title: string;
  /** the book's `title` with highlights */
  highlightedTitle: string;
  /** the book's `description` with highlights */
  highlightedDescription: string;
  /** The book's page `url` */
  url: string;
  /** display preorder icon on image */
  preorderIcon: boolean;
  /** Should hide prices? */
  hidePrices$: Observable<boolean>;

  constructor(private _helperService: HelperService, private _globalStoreQuery: GlobalQuery) {}

  /**
   * Returns the `imageId`.
   *
   * The image placeholder is set for SSR or browsers that support `IntersectionObserver`.
   */
  private _getImageId(): string {
    if (!this._helperService.isBrowser || window['IntersectionObserver']) {
      this.imageDomain = environment.imageServerUrl;
      return '/static/dk-2020-no-cover.jpg';
    }
    if (this.bookObject.image) {
      if (typeof this.bookObject.image.cdn === 'string' && this.bookObject.image.cdn) {
        return this.bookObject.image.namespace + '/' + this.bookObject.image.dir + this.bookObject.image.filename;
      }
    }
    return this.seoTitle;
  }

  ngOnInit() {
    this.hidePrices$ = this._globalStoreQuery.hidePrices$;
    if (this.bookObject) {
      this.author = this.bookObject.author;
      this.currency = this.bookObject.currency;
      this.description = this._helperService.stripHtmlTag(this.bookObject.description, this.maxLength);
      this.formats = this.bookObject.formats;
      this.price = this.bookObject.price;
      this.title = this.bookObject.displayName;
      this.highlightedTitle = this.bookObject.highlightedName;
      this.highlightedDescription = this._helperService.stripHtmlTag(this.bookObject.highlightedDescription);
      this.seoTitle = `${this.bookObject.mainImageUrl}/${this._helperService.buildSeoImageName(this.title)}`;
      this.url = this.bookObject.url;
      this.imageId = this._getImageId();

      if (this.bookObject.publishDate && this.checkPreorder) {
        const today = new Date();
        this.preorderIcon = new Date(this.bookObject.publishDate) > today;
      }
    }
  }

  /**
   * Sets the `isLoaded` to true
   * @memberof BookObjectComponent
   */
  imageLoaded() {
    this.isLoaded = true;
  }

  /**
   * If the image fails to load replaces the `imageId` with a no cover image
   * @param {any} event
   * @memberof BookObjectComponent
   */
  imageFallback(event: Event) {
    window['dataLayer'].push({
      event: 'Missing Image',
      actionVariable: this.bookObject.displayName,
    });
    this.imageDomain = environment.imageServerUrl;
    this.imageId = '/static/dk-2020-no-cover.jpg';
  }

  /**
   * Replaces the temp image with the actual image as soon it's visible on the viewport
   * @param {boolean} status
   * @memberof BookObjectComponent
   */
  isvisible(status: boolean) {
    if (status) {
      if (this.bookObject.image) {
        this.imageDomain = this.bookObject.image.cdn;
        this.imageId = this.bookObject.image.dir + '/' + this.bookObject.image.filename;
      } else {
        this.imageDomain = environment.imageServerSeoUrl;
        this.imageId = this.seoTitle;
      }
    }
  }

  /**
   * updated the highlighted description text whe the search term change for the books that remain the same.
   * @param {SimpleChanges} changes
   * @memberof BookObjectComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bookObject && !changes.bookObject.firstChange) {
      this.highlightedDescription = changes.bookObject.currentValue.highlightedDescription;
    }
  }

  /**
   * Handles click events on products within the carousel,
   * and emits event to book carousel component
   * @memberof BookObjectComponent
   */
  handleProductClick(): void {
    if (this.bookObjectType === 'bookList') {
      this.sendGAEvent.emit(this.title);
    }
  }
}
