import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookObjectInterface } from 'app/components/molecules/book-object/book-object.interface';
import { SolrDocsInterface, SolrResultInterface } from 'app/components/pages/search/search.interface';
import { HelperService } from 'app/services/helper/helper.service';
import { MailmanInterface } from 'app/services/mailman/mailman.interface';
import { ViewportService } from 'app/services/viewport/viewport.service';
import { SiteWideLabelsInterface } from 'app/store/global.store';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { SearchBarService } from './search-bar.service';

const enum KEY_CODE {
  ESCAPE = 27,
}

@Component({
  selector: 'dk-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [SearchBarService],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Output() openedQuick = new EventEmitter();

  /** The search bar input element */
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  /** The search results dropdown, when active on mobile apply a scroll-lock class to the html & body */
  @ViewChild('searchResultsSection', { static: false }) set searchResultsSection(searchResults: ElementRef) {
    this.mobileSearchResultsActive = !!searchResults && this.isMobile;
    if (this.mobileSearchResultsActive) {
      document.querySelector('html').classList.add('h__scroll-lock');
    } else {
      document.querySelector('html').classList.remove('h__scroll-lock');
    }
  }

  /** search query term observable stream */
  private _query$: Subject<string> = new Subject();
  private _unsubscribe$: Subject<any> = new Subject();

  /** What country is it? */
  country: string;

  /** Is the the quick search active */
  openQuick: boolean;

  /** the array of recent search terms */
  recentKeywords: string[] = [];

  /** The array of books from our search */
  resBooks: BookObjectInterface[];

  /** The search query term */
  searchQuery: string;

  /** The total number of books resulting from the search */
  totalResults = 0;

  /** Is this a browser? */
  isBrowser: boolean;

  /** Is this a desktop viewport? */
  isDesktop: boolean;

  /** Is this a mobile viewport? */
  isMobile: boolean;

  /** what type of page are we on? */
  pageType: string;

  /** calculated view height unit */
  vh: number;

  /** Is the mobile search results section showing? */
  mobileSearchResultsActive: boolean;

  /** The header's labels */
  labels$: Observable<SiteWideLabelsInterface>;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _searchBarService: SearchBarService,
    private _helperService: HelperService,
    private _viewportService: ViewportService
  ) {
    this._viewportService.isDesktop.subscribe(res => (this.isDesktop = res));
    this._viewportService.isMobile.subscribe(res => (this.isMobile = res));
  }

  ngOnInit(): void {
    this.isBrowser = this._helperService.isBrowser;

    this.labels$ = this._searchBarService.getLabels();

    if (this.isBrowser) {
      this.recentKeywords = this._searchBarService.getRecentSearchPhrases();
    } else {
      this.recentKeywords = [];
    }
    this._query$
      .pipe(
        debounceTime(350), // wait 350ms after each keystroke before considering the term
        distinctUntilChanged(), // ignore if next search term is same as previous
        takeUntil(this._unsubscribe$),
        switchMap(term => {
          if (!term) {
            this.totalResults = 0;
          }
          return term ? this._searchBarService.search(term) : observableOf();
        })
      )
      .subscribe((res: MailmanInterface<SolrResultInterface>) => {
        /** Array of `books` from search results, containing highlighted name properties. */
        const results: SolrDocsInterface[] = res.result.response.docs.map(doc => {
          if (
            res.result.highlighting &&
            res.result.highlighting[doc.lookup] &&
            res.result.highlighting[doc.lookup].hasOwnProperty('DisplayName')
          ) {
            return {
              ...doc,
              highlightedName: res.result.highlighting[doc.lookup].DisplayName[0],
            };
          } else {
            return doc;
          }
        });
        this.resBooks = this._helperService.transformBookObject(results);
        this.totalResults = res.result.response.numFound;
      });

    /**
     * Gets the country and query string from the URL
     */
    this._route.paramMap.pipe(takeUntil(this._unsubscribe$)).subscribe(params => {
      if (params.has('query')) {
        // setting the search bar default value to searched term on search pages
        this.searchQuery = params.get('query');
      }
      this.country = params.get('country');
    });

    /** Set the page type, so we can apply custom styling to the search bar */
    if (!this.pageType) {
      const url = this._router.url.split('/');
      if (url.length === 3) {
        this.pageType = 'HomepageComponent';
      } else if (url[2] === 'search') {
        this.pageType = 'SearchComponent';
      } else if (url[2] === 'book') {
        this.pageType = 'ProductComponent';
      } else {
        this.pageType = null;
      }
    }
  }

  /** reset the `vh` value on resize */
  @HostListener('window:resize', ['$event'])
  onresize() {
    this.vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  /**
   * Push a search term into the observable stream.
   */
  search(term: string) {
    this._query$.next(term);
  }

  /** open the quick search dropdown */
  openQuickSearch() {
    /** calculate the view height */
    this.vh = window.innerHeight * 0.01;

    /** set `vh` as a css variable */
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);

    if (!this.openQuick) {
      this.openQuick = true;
      this.openedQuick.emit(this.openQuick);
    }
  }

  /** close the quick search dropdown */
  closeQuickSearch() {
    this.openQuick = false;
    this.totalResults = -1;
    this.openedQuick.emit(this.openQuick);
  }

  /** remove term from quick search input */
  clearQuickSearch(): void {
    this.searchQuery = null;
  }

  /** hit `enter` to navigate to search page, close quick search, and add term to recent search */
  onEnter(query) {
    this._router.navigate(['/', this.country, 'search', query]);
    this.closeQuickSearch();
    this.recentKeywords = this._searchBarService.addRecentSearchPhrase(query);
  }

  /** remove all terms from recent search */
  clearRecentSearch() {
    this.recentKeywords = this._searchBarService.clearRecentSearch();
  }

  /** remove a term from the recent search */
  removeKeyword(keyword) {
    this.recentKeywords = this._searchBarService.removedRecentSearchPhrase(keyword);
  }

  /** navigate to a book product page */
  goToBook(url) {
    if (typeof url === 'string') {
      this._router.navigate(['/'].concat(url.split('/').filter(part => part)));
    }
  }

  /** hit `escape` to close quick search */
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESCAPE) {
      this.closeQuickSearch();
    }
  }

  /** when a window scroll event happens when the quick search results box is active,
   * un-focus the search input to remove the iOS keyboard */
  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if (
      this.mobileSearchResultsActive &&
      this.searchInput.nativeElement === document.activeElement // is the searchInput focussed?
    ) {
      this.searchInput.nativeElement.blur();
    }
  }
}
