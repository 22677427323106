import { Injectable } from '@angular/core';
import { HelperService } from 'app/services/helper/helper.service';
import { MailmanService } from 'app/services/mailman/mailman.service';
import { GlobalStoreService } from 'app/store/global.service';
import { MarketInterface, SiteWideLabelsInterface } from 'app/store/global.store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CountriesInterface, FooterInterface } from './footer.interface';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(
    private _mailmanService: MailmanService,
    private _globalStoreService: GlobalStoreService,
    private _helperService: HelperService
  ) {}

  getFooterContent(): Observable<FooterInterface> {
    return this.getActiveMarket().pipe(
      switchMap(market => this._mailmanService.getApi<FooterInterface>(`${market.code}/footer`))
    );
  }

  getActiveMarket(): Observable<MarketInterface> {
    return this._globalStoreService.selectActiveMarket();
  }

  /**
   * Returns the labels for the website
   */
  getLabels(): Observable<SiteWideLabelsInterface> {
    return this._globalStoreService.selectMarketLabels();
  }

  buildCountriesList(countries: CountriesInterface[]) {
    return this._globalStoreService.selectAllMarkets().pipe(
      map(markets => {
        const dkhubMarkets: CountriesInterface[] = [];
        if (markets) {
          Object.entries(markets).map(market => {
            const formatedCountry = this._buildCountryObject(market[1]);
            dkhubMarkets.push(formatedCountry);
          });
          const sortedCountries = this._helperService.sortArrayOfObjects<CountriesInterface, 'country'>(
            [...countries, ...dkhubMarkets],
            'country'
          );
          return sortedCountries;
        }
      })
    );
  }

  private _buildCountryObject(market: MarketInterface): CountriesInterface {
    return {
      country: market.name,
      url: `/${market.code}`,
      image: market.code,
    };
  }
}
