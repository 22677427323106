import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FormatState, FormatStore, ProductState, ProductStore } from './product.store';

@Injectable({
  providedIn: 'root',
})
export class ProductQuery extends QueryEntity<ProductState> {
  constructor(protected store: ProductStore) {
    super(store);
  }
}

@Injectable({
  providedIn: 'root',
})
export class FormatQuery extends QueryEntity<FormatState> {
  constructor(protected store: FormatStore) {
    super(store);
  }
}
