import { Injectable } from '@angular/core';
import { SolrResultInterface } from 'app/components/pages/search/search.interface';
import { MailmanInterface } from 'app/services/mailman/mailman.interface';
import { MailmanService } from 'app/services/mailman/mailman.service';
import { StorageService } from 'app/services/storage/storage.service';
import { GlobalStoreService } from 'app/store/global.service';
import { SiteWideLabelsInterface } from 'app/store/global.store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ExpirableSearchPhraseInterface, ExpirableSearchPhraseSetInterface } from './search-bar.interface';

const maxSearchTermsShow = 6;
const maxSearchTermsStore = 6;
const maxDaysTermValid = 30;

@Injectable({
  providedIn: 'root',
})
export class SearchBarService {
  constructor(
    private mailmanService: MailmanService,
    private storage: StorageService,
    private _globalStoreService: GlobalStoreService
  ) {}

  /**
   * Forms query string to send to `mailmanService` and search books.
   * @returns {Observable} An Observable of search results
   * @param {string} term - query term
   * @memberof SearchBarService
   */
  search(term: string): Observable<MailmanInterface<SolrResultInterface>> {
    return this._globalStoreService.selectActiveMarket().pipe(
      switchMap(({ defaultLanguage }) => {
        term = term.trim();
        const queryTerm = term.length > 2 ? `${term} OR ${term}*` : term;
        const searchFields = `fl=displayName:DisplayName,description:Description,mainImageUrl:MainImageURL,
          url:URL,price:Price,type:DocumentTypeString,id:Code,lookup:ContentKey,currency:Currency,
          publishDate:PublishDate`;
        // tslint:disable-next-line: max-line-length
        const searchParams = `fq=DocumentTypeString:Product&fq=LanguageCode:${defaultLanguage}&rows=6&wt=json&q=${queryTerm}`;
        const searchHighlighting = 'hl=on&hl.fl=DisplayName&hl.method=unified';
        const recencyBoost = 'defType=edismax&bq=PublishDate:[NOW/DAY-1YEAR TO NOW/DAY]';
        return this.mailmanService.searchBooks(`${searchFields}&${searchParams}&${searchHighlighting}&${recencyBoost}`);
      })
    );
  }

  addRecentSearchPhrase(newPhrase: string): Array<string> {
    let tempKeywords = this.getTimedSearchPhrases();
    if (newPhrase) {
      const newTimedPhrase: ExpirableSearchPhraseInterface = {
        phrase: this.normalizeSearchPhrase(newPhrase),
        day: Math.floor(new Date().getTime() / 86400000),
      };

      tempKeywords = tempKeywords.filter(oldTimedPhrase => oldTimedPhrase.phrase !== newTimedPhrase.phrase);
      tempKeywords.unshift(newTimedPhrase);
      this.storage.writeObject('recentSearches', tempKeywords.slice(0, maxSearchTermsStore));
    }
    return tempKeywords.slice(0, maxSearchTermsShow).map(timedPhrase => timedPhrase.phrase);
  }

  removedRecentSearchPhrase(sadPhrase: string): Array<string> {
    let tempKeywords = this.getTimedSearchPhrases();
    const phraseCount = tempKeywords.length;
    tempKeywords = tempKeywords.filter(oldTimedPhrase => oldTimedPhrase.phrase !== sadPhrase);
    if (tempKeywords.length < phraseCount) {
      this.storage.writeObject('recentSearches', tempKeywords);
    }
    return tempKeywords.slice(0, maxSearchTermsShow).map(timedPhrase => timedPhrase.phrase);
  }

  clearRecentSearch(): Array<string> {
    this.storage.writeObject('recentSearches', []);
    return [];
  }

  // Get array of recent search phrases, marked with "expiration date" (See ExpirableSearchPhraseSetInterface)
  getTimedSearchPhrases(): ExpirableSearchPhraseSetInterface {
    const currentDay = new Date().getTime() / 86400000;
    const rawPhrases: ExpirableSearchPhraseSetInterface = <ExpirableSearchPhraseSetInterface>(
      this.storage.readObject('recentSearches')
    );
    return !Array.isArray(rawPhrases)
      ? []
      : rawPhrases.filter(
          (datedPhrase: ExpirableSearchPhraseInterface) =>
            datedPhrase && typeof datedPhrase.phrase === 'string' && datedPhrase.day + maxDaysTermValid >= currentDay
        );
  }

  // Get array of recent search phrases (strings), no longer marked with "expiration date"
  getRecentSearchPhrases(): Array<string> {
    return this.getTimedSearchPhrases()
      .slice(0, maxSearchTermsShow)
      .map(timedPhrase => timedPhrase.phrase);
  }

  private normalizeSearchPhrase(term: string) {
    return term
      .split(/\s+/) // Split into words
      .filter(word => word) // Remove 'empty' words
      .map(word => word[0].toUpperCase() + word.substr(1)) // // Upercase first letter
      .join(' '); // Join back into sentence
  }

  /**
   * Returns the labels for the website
   */
  getLabels(): Observable<SiteWideLabelsInterface> {
    return this._globalStoreService.selectMarketLabels();
  }
}
