import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BreadcrumbsComponent } from 'app/components/atoms/breadcrumbs/breadcrumbs.component';
import { CheckboxComponent } from 'app/components/atoms/dk-checkbox/checkbox.component';
import { DkIconComponent } from 'app/components/atoms/dk-icon/dk-icon.component';
import { InputYellowComponent } from 'app/components/atoms/dk-input-yellow/dk-input-yellow.component';
import { InputComponent } from 'app/components/atoms/dk-input/dk-input.component';
import { DkPictureComponent } from 'app/components/atoms/dk-picture/dk-picture.component';
import { ResourceBlockComponent } from 'app/components/atoms/resource-block/resource-block.component';
import { RoundaboutComponent } from 'app/components/atoms/roundabout/roundabout.component';
import { SecondaryNavigationComponent } from 'app/components/atoms/secondary-navigation/secondary-navigation.component';
// import { ToastComponent } from 'app/components/atoms/toast/toast.component';
// Components
import { BookCarouselComponent } from 'app/components/molecules/book-carousel/book-carousel.component';
import { BookObjectComponent } from 'app/components/molecules/book-object/book-object.component';
import { ChangeCountryComponent } from 'app/components/molecules/change-country/change-country.component';
import { FooterComponent } from 'app/components/molecules/footer/footer.component';
import { GalleryMegaComponent } from 'app/components/molecules/gallery-mega/gallery-mega.component';
import { ModalComponent } from 'app/components/molecules/modal/modal.component';
import { PageHeaderComponent } from 'app/components/molecules/page-header/page-header.component';
import { RecentlyViewedComponent } from 'app/components/molecules/recently-viewed/recently-viewed.component';
import { ResourceGridComponent } from 'app/components/molecules/resource-grid/resource-grid.component';
import { SearchBarComponent } from 'app/components/molecules/search-bar/search-bar.component';
import { SearchFilterComponent } from 'app/components/molecules/search-filter/search-filter.component';
import { FourohfourComponent } from 'app/components/pages/fourohfour/fourohfour.component';
// Directives
import { IsVisibleDirective } from 'app/directives/is-visible.directive';
import { CookieWarningComponent } from '../../components/atoms/cookie-warning/cookie-warning.component';
import { ValidateGroupCheckboxDirective } from '../../directives/validate-group-checkbox/validate-group-checkbox.directive';
// Pipes
import { DescriptionHighlightPipe } from '../../pipes/description-highlight.pipe';
import { UrlSafePipe } from '../../pipes/url-safe.pipe';
@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  declarations: [
    BookCarouselComponent,
    BookObjectComponent,
    BreadcrumbsComponent,
    ChangeCountryComponent,
    CheckboxComponent,
    CookieWarningComponent,
    DkPictureComponent,
    DkIconComponent,
    FooterComponent,
    FourohfourComponent,
    GalleryMegaComponent,
    InputComponent,
    InputYellowComponent,
    IsVisibleDirective,
    ModalComponent,
    PageHeaderComponent,
    RecentlyViewedComponent,
    RoundaboutComponent,
    SearchBarComponent,
    SearchFilterComponent,
    SecondaryNavigationComponent,
    // ToastComponent,
    ValidateGroupCheckboxDirective,
    UrlSafePipe,
    DescriptionHighlightPipe,
    ResourceBlockComponent,
    ResourceGridComponent,
  ],
  exports: [
    BookCarouselComponent,
    BookObjectComponent,
    BreadcrumbsComponent,
    ChangeCountryComponent,
    CheckboxComponent,
    CommonModule,
    CookieWarningComponent,
    DkPictureComponent,
    DkIconComponent,
    FooterComponent,
    FormsModule,
    FourohfourComponent,
    GalleryMegaComponent,
    InputComponent,
    InputYellowComponent,
    IsVisibleDirective,
    ModalComponent,
    PageHeaderComponent,
    RecentlyViewedComponent,
    RoundaboutComponent,
    RouterModule,
    SearchBarComponent,
    SearchFilterComponent,
    SecondaryNavigationComponent,
    // ToastComponent,
    ValidateGroupCheckboxDirective,
    UrlSafePipe,
    ResourceBlockComponent,
    ResourceGridComponent,
  ],
})
export class SharedModule {}
