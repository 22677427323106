import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PageScrollTrackingInterface } from './previousPageScroll.interface';

@Injectable()
export class PreviousPageScrollService {
  /** The observable recording scroll depth */
  pagePosition$: BehaviorSubject<PageScrollTrackingInterface> = new BehaviorSubject({
    filterOptions: {},
    filterLabels: {},
  });

  /** The page where we want to retain the scroll depth */
  pagesToRestoreScroll = new RegExp('category|promotion');

  /** Return the DOM node from the page URL for either a book or article */
  findBookElementByUrl(url: string): HTMLElement {
    return (
      document.querySelector('.book-object[href*="' + url + '"]') ||
      document.querySelector('dk-article-object [href*="' + url + '"]')
    );
  }
}
