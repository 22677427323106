import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { MailmanService } from 'app/services/mailman/mailman.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GlobalQuery } from './global.query';
import {
  GlobalState,
  GlobalStore,
  MarketInterface,
  MarketsResponseInterface,
  SiteWideLabelsInterface,
} from './global.store';

@Injectable({ providedIn: 'root' })
export class GlobalStoreService {
  constructor(
    private _globalStore: GlobalStore,
    private _mailman: MailmanService,
    private _globalQuery: GlobalQuery,
    private _routerQuery: RouterQuery
  ) {}

  updateGlobalStore(update: Partial<GlobalState>) {
    this._globalStore.update(_ => ({ ...update }));
  }

  /**
   * Sets and returns an market to active
   * @param country The market country code to be set active
   */
  setActiveMarket$(country: string): Observable<MarketInterface> {
    return this._mailman.getApi<MarketsResponseInterface>('markets').pipe(
      map(markets => {
        for (const [key, market] of Object.entries(markets)) {
          market.code = key;
        }
        const activeMarket = markets[country];
        this.updateGlobalStore({ activeMarket: activeMarket, markets });
        return activeMarket;
      })
    );
  }

  /**
   * Returns the localised labels for the website
   */
  selectMarketLabels(): Observable<SiteWideLabelsInterface> {
    return this._globalQuery.select().pipe(map(({ labels }) => labels));
  }

  selectAllMarkets(): Observable<MarketsResponseInterface> {
    return this._globalQuery.select(state => state.markets);
  }

  selectActiveMarket(): Observable<MarketInterface> {
    return this._routerQuery.selectParams('country').pipe(
      switchMap(country => {
        if (this._globalQuery.getValue().activeMarket) {
          return this._globalQuery.select(state => state.activeMarket);
        } else {
          return this.setActiveMarket$(country);
        }
      })
    );
  }
}
