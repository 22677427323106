import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductInterface } from 'app/components/pages/product/product.interface';
import { HelperService } from 'app/services/helper/helper.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RecentlyViewedService } from './recently-viewed.service';

@Component({
  selector: 'dk-recently-viewed',
  templateUrl: './recently-viewed.component.html',
  styleUrls: ['./recently-viewed.component.scss'],
  providers: [RecentlyViewedService],
})
export class RecentlyViewedComponent implements OnInit, OnDestroy {
  /** The observables garbage collector */
  private _unsubscribe$: Subject<any> = new Subject();

  /** Check whether the application is running on an actual browser */
  private _isBrowser: boolean = this.helperService.isBrowser;

  /** Recently viewed books */
  books$: Observable<ProductInterface[]>;

  labels$: Observable<string>;

  constructor(private helperService: HelperService, private _recentlyViewedService: RecentlyViewedService) {}

  ngOnInit() {
    if (this._isBrowser) {
      this.books$ = this._recentlyViewedService.selectRecentProducts().pipe(takeUntil(this._unsubscribe$));
      this.labels$ = this._recentlyViewedService.selectLabels().pipe(takeUntil(this._unsubscribe$));
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  /**
   * Removes a book from the list
   * @param {string} url
   * @memberof RecentlyViewedComponent
   */
  removeBook(url: string) {
    this.books$ = this.books$.pipe(map(books => books.filter(book => book.url !== url)));
    this._recentlyViewedService.deleteBook(url);
  }

  /**
   * If a image fails to load removes the book from the list
   * @param {string} url
   * @memberof RecentlyViewedComponent
   */
  imageError(url: string) {
    console.error('Recently Viewed Books: Removing book cover as the image failed to load. Book: ', url);
    this.removeBook(url);
  }
}
