import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalQuery } from './modal.query';
import { ModalState, ModalStore } from './modal.store';

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private _modalStore: ModalStore, private _modalQuery: ModalQuery) {}

  setActiveModal(name: string) {
    this._modalStore.update(_ => ({ modalName: name }));
  }

  getActiveModal(): Observable<string> {
    return this._modalQuery.select().pipe(map((name: ModalState) => name.modalName));
  }

  closeActiveModal() {
    this._modalStore.update(_ => ({ modalName: '' }));
  }
}
